import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardOne from "./CardOne";
import image from "../../Assets/tr-1.jpg"
import pic from "../../Assets/f-d.jpeg"
import pic2 from "../../Assets/tr-2.jpeg"
import AboutCard from "./FirstCard";
import CardO from "../Training/CardO";
import Newcard from "./Newcard";
import { ImEarth } from "react-icons/im";
import { ImBooks } from "react-icons/im";
import { FaChalkboardTeacher, FaMoneyBill } from "react-icons/fa";
import { FaStamp } from "react-icons/fa";
import FadeComponentOne from "./FadeComponentOne";
import FadeComponentTwo from "./FadeComponentTwo";
import FadeComponentThree from "./FadeComponentThree";
import Tilt from "react-parallax-tilt";

function Training() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Training</strong>
            </h1>
            <AboutCard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            {/* <img src={image} alt="about" className="img-fluid" /> */}
            <Tilt>
              <img src={image} className="shadow rounded-3 md:mt-0 w-full mt-5" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        <div>
          <FadeComponentOne />
        </div>
        <div className="fade-two">
          <FadeComponentTwo />
        </div>
        <div className="fade-three">
          <FadeComponentThree />
        </div>
        <br />
        <br />

        <Row>
          <Col md={6}><div className="Cards-div my-1">
            <div>
              <img src={pic} alt="about" className="img-training" />
            </div>
            <div>
              <CardOne />
            </div>
          </div></Col>
          <Col md={6}> <div className="Cards-div my-1">
            <div className="">
              <img src={pic2}  alt="about" className="img-training" />
            </div>
            <div>
              <CardO />
            </div>
          </div></Col>
        </Row>

        {/* <div className="TwoCards-div">
          <div className="Cards-div">
            <div>
              <img src={pic} alt="about" className="img-training" />
            </div>
            <div>
              <CardOne />
            </div>
          </div> */}

         
        {/* </div> */}

        <br />
        <br />
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
          <strong className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>
            Our training focuses on teaching relevant skills used in the IT
            industry, and we believe anyone can learn to code with a structured
            learning approach
          </strong>
        </h1>

        <br />
        <br />
        <br />
        <div>
          <p style={{ color: "black" }}>
            <h2 className="purple" style={{ fontWeight: "bold" , fontFamily:"'Poppins', sans-serif"}}>
              Courses Offered
            </h2>
            <br />
            Web development course, web development and design course, cloud
            computing, big data Hadoop, Mobile application development, and
            digital marketing.If you are looking for a full-stack web
            development courses then you must go for Zyder.
          </p>
        </div>
        <div>
          <Newcard />
        </div>

        <div>
          <h2 className="purple">Reasons to choose us:- </h2>
          <br />
          <br />
          <Row>
            <Col>
              <Row>
                <ImEarth className="purple" size={70} />
              </Row>
              <Row>
                <h4 style={{ color: "black" }}>----</h4>
              </Row>
              <Row>
                <h4 style={{ color: "black", fontFamily:"'Poppins', sans-serif" }}> World Class Syllabus</h4>
              </Row>
            </Col>
            <Col>
              <Row>
                <ImBooks className="purple" size={70} />
              </Row>
              <Row>
                <h4 style={{ color: "black" }}>----</h4>
              </Row>
              <Row>
                <h4 style={{ color: "black", fontFamily:"'Poppins', sans-serif"}}> Multiple Courses</h4>
              </Row>
            </Col>
            <Col>
              <Row>
                <FaChalkboardTeacher className="purple" size={70} />
              </Row>
              <Row>
                <h4 style={{ color: "black" }}>----</h4>
              </Row>
              <Row>
                <h4 style={{ color: "black", fontFamily:"'Poppins', sans-serif" }}>
                  Highly Experienced Faculty Team
                </h4>
              </Row>
            </Col>
            <Col>
              <Row>
                <FaStamp className="purple" size={70} />
              </Row>
              <Row>
                <h4 style={{ color: "black" }}>----</h4>
              </Row>
              <Row>
                <h4 style={{ color: "black", fontFamily:"'Poppins', sans-serif" }}>100 % Placement Assistance</h4>
              </Row>
            </Col>
            <Col>
              <Row>
                <FaMoneyBill className="purple" size={70} />
              </Row>
              <Row>
                <h4 style={{ color: "black" }}>----</h4>
              </Row>
              <Row>
                <h4 style={{ color: "black", fontFamily:"'Poppins', sans-serif" }}> Affordable Fee Structure</h4>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="Three-cards"></div>
      </Container>
    </Container>
  );
}
export default Training;
