
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/it-1.jpeg";
import FadeInComoponent from "./FadeInComponent";
import FadeInComoponentOne from "./FadeInComponentOne";
import FadeInComoponentTwo from "./FadeInComponentTwo";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepsFour";
import StepFive from "./Steps/StepFive";
import StepSix from "./Steps/StepSix";
import StepSeven from "./Steps/StepSeven";
import Tilt from "react-parallax-tilt";

function ItDevelopment() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px", fontFamily: "'Poppins', sans-serif" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "30px" }}>
              <strong className="purple"> Our IT Department</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "140px", paddingBottom: "50px", paddingLeft: "50px" }}
            className="about-img"
          >
            <Tilt>
              <img src={laptopImg} className="shadow rounded-3 md:mt-0 w-full mt-5" alt="avatar"  />
            </Tilt>
          </Col>

        </Row>

        <h1 className="project-heading">
          <strong className="purple" style={{ fontFamily: "'Poppins', sans-serif" }}>Before getting started we believe in knowing our clients and our goals </strong>
        </h1>
        <br />
        <br />

        <FadeInComoponent />
        <br />
        <br />
        <br />
        <h1 className="project-heading">
          <strong className="purple" style={{ fontFamily: "'Poppins', sans-serif" }}> Our Software Development Process </strong>
        </h1>
        <br />
        <br />
        <FadeInComoponentOne />
        <br />
        <br />
        <FadeInComoponentTwo />
        <br />
        <br />
        <h1 className="project-heading">
          <strong className="purple"> After these parts the process of development
            takes place</strong>
        </h1>
        <br />
        <br />
        <div className="it-steps">
          <div className="space-between-steps"><StepOne /></div>
          <div className="space-between-steps"><StepTwo /></div>
          <div className="space-between-steps"><StepThree /></div>
        </div>
        <div className="it-steps">
          <div className="space-between-steps"><StepFour /></div>
          <div className="space-between-steps"><StepFive /></div>
          <div className="space-between-steps"><StepSix /></div>
          <div className="space-between-steps"><StepSeven /></div>
        </div>

        <h1 className="project-heading">
          <strong className="purple" style={{ fontFamily: "'Poppins', sans-serif" }}> Let’s discuss your next project</strong>
        </h1>
      </Container>
    </Container>
  )

}

export default ItDevelopment;
