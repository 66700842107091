import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link,NavLink } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";
import { CgToolbox } from "react-icons/cg";
import { CgFileDocument } from "react-icons/cg";
import ZyderSolutionsLogo from "../Assets/zyder-logo.png";
import { CgBoard } from "react-icons/cg";


function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="lg"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container className="bg-white">
        
      <Navbar.Brand href="/" className="d-flex">
          <img src={ZyderSolutionsLogo} className="imglogo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <nav>
              <NavLink 
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
              as={Link} to="/" onClick={() => updateExpanded(false)}>
                <div className="icon-screens">
                <AiOutlineHome style={{ marginBottom: "2px" }} /> <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>Home</div></div>
              </NavLink>
            </nav>

            <nav>
              <NavLink
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <div className="icon-screens">
                <AiOutlineUser style={{ marginBottom: "2px" }} /> <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>About</div></div>
              </NavLink>
            </nav>

            <nav>
              <NavLink
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
                as={Link}
                to="/staffing"
                onClick={() => updateExpanded(false)}
              >
                <div className="icon-screens">
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>Staffing</div></div>
              </NavLink>
            </nav>

            <nav>
              <NavLink
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
                as={Link}
                to="/Development"
                onClick={() => updateExpanded(false)}
              ><div className="icon-screens">
                <CgFileDocument style={{ marginBottom: "2px" }} />  <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>Development</div></div>
              </NavLink>
            </nav>

            <nav>
              <NavLink
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
                as={Link}
                to="/Services"
                onClick={() => updateExpanded(false)}
              >
                <div className="icon-screens">
                <CgToolbox style={{ marginBottom: "2px" }} /> <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>Services</div></div>
              </NavLink>
            </nav>
            
            <nav>
              <NavLink
               style={({ isActive}) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  borderBottomWidth : isActive ? 2 :'',
                  borderBottomColor: isActive ? "purple" :''
                };
              }}
              className='nav-link'
                as={Link}
                to="/training"
                onClick={() => updateExpanded(false)}
              >
                <div className="icon-screens">
                <CgBoard style={{ marginBottom: "2px" }} /> <div className="screen-nav" style={{fontFamily:"'Poppins', sans-serif"}}>Training</div></div>
              </NavLink>
            </nav>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
