import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMobile } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { CgLaptop } from "react-icons/cg";
import { CgWebsite } from "react-icons/cg";
function Newcard() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" style={{ height: "260px"}}>
      <CgLaptop className="purple" />

      <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Frontend Technologies </h3>
        <></>
        <p style={{ fontSize: "12px" ,color:"black", fontFamily:"'Poppins', sans-serif"}}>React, Angular, Express, Vuejs, Emberjs, HTMLCSS, Javascript</p>


      </Col>
      <Col xs={4} md={2} className="tech-icons" style={{ height: "260px" }}>
        <AiFillSetting className="purple"/>

        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Backend Technologies </h3>
        <></>
        <p style={{ fontSize: "12px" ,color:"black", fontFamily:"'Poppins', sans-serif"}}>Java , Python , Ruby on Rails , Node.js , DATA SCIENCE , MACHINE LEARNING , ARTIFICIAL INTELLIGENCE</p>


      </Col>
      <Col xs={4} md={2} className="tech-icons" style={{ height: "260px" }}>
        <FaMobile className="purple" />

        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Mobile Technologies </h3>
        <></>
        <p style={{ fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>IOS /Anroid , Flutter , React Native , Swift , Kotlin</p>


      </Col>
      <Col xs={4} md={2} className="tech-icons" style={{ height: "260px" }}>

        <CgWebsite className="purple" />
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Web Designing </h3>
        <></>
        <p style={{ fontSize: "12px",color:"black", fontFamily:"'Poppins', sans-serif" }}>Coral , Adobe Photoshop , Pagemaker , Web Page Design (HTML CSS)</p>
      </Col>
    </Row>
  );

}

export default Newcard;
