import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", color:"Black",fontFamily: "'Poppins', sans-serif" }}>
            <span className="purple"> </span>
            Finding the right talent for your IT team can be a daunting task,
              but not when you work with Zyder. Our staffing services are
              designed to help you find the best fit for your business. We
              provide staffing for a range of IT roles, including developers,
              project managers, QA engineers, and more. Our team of recruiters
              has extensive experience in the IT industry and understands the
              unique skill sets required for each role.We not only help match
              our talent to the right jobs, but also help businesses reach their
              organisations’ goals.
              <br />
              <br />
              Zyder Solutions has a dedicated team that works relentlessly round
              the clock, to bring the best candidates into the selection arena
              of the corporate world <br />
              <br />
              Our talent acquisition process is efficient to support you in
              finding the right fit for your organisation within 7-10 days. From
              reviewing resumes to identifying quality candidates & interviewing
              them, we invest resources to get the best talent of the industry.{" "}
              <br />
              <br />
              Bridge the hiring gap with our Staffing Solutions,{" "}
              <b>"Your success is our priority"</b>
            </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
