import React from 'react';

const MissionVisionCard = ({ title, text }) => {
  return (
    <div className="card mb-4 h-100" style={{ backgroundColor: 'white', borderRadius: '10px',  }}>
      <div className="card-body text-center">
        <h3 className="card-title text-center" style={{ color: '#253e91', paddingTop: "20px", borderBottom: '3px solid #253e91', paddingBottom: '10px', fontFamily:"'Poppins', sans-serif" }}>{title}</h3>
        <p className="card-text text-center" style={{ textAlign: "center", color: 'black', justifyContent: "center", paddingTop: "30px", fontFamily:"'Poppins', sans-serif" }}>{text}</p>
      </div>
    </div>
  );
};

const MissionVision = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-4 mb-4">
          <MissionVisionCard
            title="Our Mission"
            text="Zyder Solutions is to deliver reliable, scalable, world-class services and solutions. Our focus is to provide above-and-beyond services that drive client and partner success."
          />
        </div>
        <div className="col-md-4 mb-4">
          <MissionVisionCard
            title="Our Vision"
            text="To build rewarding, long-term relationships by continually helping others to be more effective and efficient via technology."
          />
        </div>
        <div className="col-md-4 mb-4">
          <MissionVisionCard
            title="Our Specialties"
            text="Staffing - Indian & US Staffing, Information Technology & Services, Resource Outsourcing, and CRM."
          />
        </div>
      </div>
    </div>
  );
};

export default MissionVision;

