import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() { 
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", color:"black", fontFamily:"'Poppins', sans-serif" }}>
            <br />
            Welcome to Zyder, the IT service company that's not just serious about technology, but also knows how to have a little fun while providing top-notch solutions. Our team of experts is here to help you with all your IT needs, including development, staffing, services, and training.
            <span className="purple"> </span>
            <span className="purple"></span>
            <br />
            <br /> 
          </p>

          <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}} >
            We are passionate about revolutionizing businesses through the latest technology.
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}} >
            Zyder Solutions provides business-friendly solutions to help clients succeed and be future-ready.
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points" >
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            We are not only cost-effective, we also work with agile methodologies and in the best time-zones.
            </p>
            </div>
            </div>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;