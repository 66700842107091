import React from "react";

function CardOne() {
    return (
        <div>

            <div className="card-heading">
                <h5 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Ensurity</h5>
            </div>

            <p className="card-one" style={{fontFamily:"'Poppins', sans-serif"}}>
                We ensure training is imparted by specialists with proven subject matter expertise and who have spent over a decade in their area of specialisation.
                Our faculty are highly competent, skilled and dedicated to giving their best towards the professional development of our students.
                Besides training, we also provide placement assistance to our students and most of the big corporates in the corporate world hire our trained talent.
                Our training focuses on teaching relevant skills used in the IT industry, and we believe anyone can learn to code with a structured learning approach
            </p>

        </div>
    )
}
export default CardOne;