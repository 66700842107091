import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepTwo extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Design & Architecture</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Design is a creative Step of project development where the requirement of the client is sketched and shared before starting the actual development.
Our experienced Designers use only the latest design tools and techniques to create future-ready solutions.
This is where the end product’s look, feel, and function come into focus We:
Review designs with our engineering team every week.
Determine best-fit software development architecture.


            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepTwo;