import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepThree extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Development</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Once we have a clear vision for design and functionality, we initiate a call with you, the project manager, and the developers.
Actual coding/ development starts at this stage. By using the right tools/ technologies, the development is done within the stipulated time.
Leveraging years of experience, our developers optimise the code to deliver perfect development.
            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepThree;