import React from "react";

function CardOne() {
    return (
        <div>

            <div className="card-heading mt-3" >
                <h5 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Platform where you will learn frontened technologies</h5>
            </div>

            <p className="card-one mb-1" style={{fontFamily:"'Poppins', sans-serif",}}>
                Backend Technologies , Mobile Technologies & Web Designing.
                You can choose any course according to your requirements.
                We are also providing advanced Software/IT courses like BIGDATA HADOOP,
                SALESFORCE, CLOUD & Devops so that you can enhance your skills
                and get better opportunities in Software industries.
                We start training the student from very basic level
                and try to slowly bring them to
                the expert level in programming
            </p>

        </div>
    )
}
export default CardOne;