import React from 'react';

const Feature = ({ icon, title, description }) => {
  return (
    <div className="col-md-4"> 
      <div className="d-flex flex-column align-items-center">
        <i className={`fas fa-${icon} fa-3x mb-3`} style={{ color: 'black', fontFamily:"'Poppins', sans-serif" }}></i>
        <h4 style={{ fontSize: "26px", borderBottom: "3px solid #253e91", paddingBottom: "10px", color:"#253e91", fontFamily:"'Poppins', sans-serif" }}>{title}</h4>
        <p style={{ color: "black", paddingTop: "10px", fontFamily:"'Poppins', sans-serif"}}>{description}</p>
      </div>
    </div> 
  );
};

const WhyChooseUs = () => {
  return (
    <section className="py-5" style={{ backgroundColor: '#f8f9fa', color: "black", borderRadius: '20px' }}>
      <div className="container">
        <div className="row">
          <div className="col text-center" style={{ fontSize: "21px", color: 'black' }}>
            <p className="lead text-muted" style={{ fontWeight: "bold", fontSize: "26px", fontFamily:"'Poppins', sans-serif" }}><strong>CREATING IDEAS WITH PASSION</strong></p>
          </div>
        </div>
        <div className="row">
          <Feature icon="shield-alt" title="Secure" description="Ensured quality, confidentiality and client loyalty." />
          <Feature icon="users" title="Experienced" description="Highly talented team delivering results on-time and within budget enabling enterprises to achieve greater results." />
          <Feature icon="star" title="Affordable" description="Competitively cost effective, On-Time & Within Budget, Services at affordable and competitive prices." />

        </div>
      </div>
    </section>

  )
};

export default WhyChooseUs;
