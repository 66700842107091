
import React, { useState } from "react";
import { Container, Row, Col, Accordion, Form } from "react-bootstrap";
import Particle from "../Particle";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { data } from "../../utils/utils";
import Fade from "react-reveal/Fade";
import { zyderfunc } from "../../utils/utils";
import FormDot from "./FormDot";
import imgage from "../../Assets/st-1.jpeg"
import imgages from "../../Assets/st-2.jpeg"
import Tilt from "react-parallax-tilt";
import AboutCard from "./AboutCard";

function Staffing() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  return (
    <Container fluid className="">
      <Container>
        <Particle />

        <h1 className="project-heading mt-20" style={{fontFamily: "'Poppins', sans-serif"}}>
          <strong className="purple" style={{fontFamily: "'Poppins', sans-serif"}}>Find Right Employees For Your Business </strong>
        </h1>

        <Row>
          <Col md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }} >
            <AboutCard/>
          </Col>
          <Col md={5} className="mt-5">
          <Tilt>
              <img src={imgage} className="shadow rounded-3 md:mt-0 w-full mt-5 " alt="avatar" />

            </Tilt>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "10px" }} className="mt-5">
          <Col md={6} xl={8}>
            {data.map((val) => {
              return (
                <Accordion
                  open={open === 1}
                  animate={customAnimation}
                  className="mt-2  bg-[#263f92] leading-9  border border-secondary"
                >
                  <AccordionHeader onClick={() => handleOpen(1)}>
                    {val.title}
                  </AccordionHeader>
                  <AccordionBody className="text-white text-left ...">
                    {val.discreption}
                  </AccordionBody>
                </Accordion>
              );
            })}
          </Col>
          <Col md={6} xl={4}>
          <Tilt>
              <img src={imgages} className="shadow rounded-3 w-full mt-2 h-64" alt="avatar" />

            </Tilt>
          </Col>
        </Row>

        <h1 className="text-[#263f92]  my-16">
          Why should you outsource your staffing function to{" "}
          <span className="purple">Zyder Solutions</span>
        </h1>
      </Container>
      <div>
        <Fade left>
          <Container>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl">
              <ul className="text-left mt-5 leading-10">
                {zyderfunc.map((val) => {
                  return <li>{val.point}</li>;
                })}
              </ul>
            </div>
          </Container>
        </Fade>
      </div>
      <Container>
        <h1 className="text-[#263f92]  my-16">
          What <span className="text-[#263f92]">We do</span>
        </h1>

        <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl">
          <p className="text-left mt-5 leading-10 p-4">
            Before we start looking for candidates, we first study your
            requirements in detail, undertake comprehensive research on the
            industry, and create interest around your company to attract the
            best talent. No other staffing agency will be able to match the
            quality of our candidates which we identify through our
            tried-and-tested processes. We will customise our talent search to
            your specific requirements so that we can shortlist the individuals
            or teams that are most capable of handling the unique challenges
            that they will face at the organisation.
          </p>
        </div>
        <div>
          <h1 className="text-[#263f92]  mt-16">
            OUR <span className="text-[#263f92]">PROCESS</span>{" "}
          </h1>

          <p>Let Us Give You The Best Of The Best.</p>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Contract signature</h1>
              <p>
                Together with the Clients, we prepare all the terms and
                conditions for the collaboration in the contract.
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Understanding Your Requirements</h1>
              <p>
                Our first priority is you and what you need. Which is why we
                begin by asking the most important question, “what are you
                looking for
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Sourcing And Screening Candidates</h1>
              <p>
                By using our best resources and looking through the magnified
                vision of your needs, we arrange the candidates.
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Preliminary Assessment</h1>
              <p>
                Our job is not just to bring the crowd but also filter your
                choices within that crowd. Time saving 101.
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Final Interview And Closing The Call</h1>
              <p>
                We do not leave the stage unless the curtains are drawn, so
                believe us when we say that our performance is completely
                targeted to your satisfaction.
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h1>Warranty Period</h1>
              <p>
                We provide warranties if the candidate fails to pass the
                probation period agreed in the contract. We guarantee a one-time
                free replacement or a refund of the order amount.
              </p>
            </div>
          </Fade>
        </div>

        <div>
          <h1 className="text-[#263f92]  mt-16">
            Our <span className="text-[#263f92]">Warranties</span>{" "}
          </h1>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h2>Free Replacement</h2>
              <p>
                If the candidate fails the probation period (as agreed in the
                contract), we guarantee a one-time free replacement or a refund
                of the order amount.
              </p>
            </div>
          </Fade>
          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h2>Commitment</h2>
              <p>
                We do not offer any other positions to candidates provided by us
                for the duration of their employment with you.
              </p>
            </div>
          </Fade>

          <Fade top>
            <div className="w-full bg-[#263f92] text-white mt-16 rounded-xl text-left p-3">
              <h2>Confidence</h2>
              <p>
                We will only recommend to hire a candidate if we are confident
                in the outcome
              </p>
            </div>
          </Fade>
          <Fade>
            <br />
            <br />


            <h1 className="text-[#263f92]  mt-16">
              Contact <span className="text-[#263f92]">Us</span>{" "}
            </h1>
            <FormDot />
          </Fade>
        </div>
      </Container>
    </Container>
  );
}

export default Staffing;

