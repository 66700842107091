import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", color:"Black", fontFamily: "'Poppins', sans-serif" }}>
            <span className="purple">Zyder Solutions </span>
            is a software development company that specializes in custom software,
            web and mobile applications.
            We are passionate about revolutionizing businesses through the latest technology.
            <br />< br />At Zyder, we believe in creating custom software solutions
            that fit your unique needs. Our experienced developers
            use cutting-edge technologies and frameworks to deliver
            high-quality software that is robust, scalable, and user-friendly.
            We work closely with our clients to understand their business
            requirements and provide software solutions that help them achieve
            their goals. And if you're the kind of person who's always thinking
            outside the box, having considerable vision, you could be adept at
            formulating new, more effective ways of doing things.

            <br />
            <br />
            Our experts serve Fortune 500s to midsize businesses and
            startups. Our methodologies enable us to effectively handle
            software projects of any scale and complexity, quickly and
            cost effectively.
            <br />
            <br />
            Zyder Solutions listens to what clients need and improves or builds new
            products to meet those needs. Everything is built in-house, giving
            them complete control of the design, development and deployment of
            application updates and the ability to help move you forward. Zyder
            Solutions offers a complete suite of application and software
            development services, and we would love to partner with you
            <br />
            <br />
            We help Software Service companies to take their company to the NEXT LEVEL in terms of :
            <br />
            <br />
            < p/>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" >
            Increase revenue
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" >
            New Technology Adoption
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" >
            Increasing Gross Margin of projects
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" >
            Zero Error Delivery Management
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" >
            Customer satisfaction through support
            </p>
            </div>
            </div>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
