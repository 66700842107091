import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/abt.jpeg";
import WhyChooseUs from "./WhyChooseUs";
import MissionVision from "./MissionVision";
import Tilt from "react-parallax-tilt";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px", fontFamily: "'Poppins', sans-serif" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em" }}>
              <strong className="purple"> Who Are We</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "140px", paddingBottom: "50px", paddingLeft: "50px" }}
            className="about-img"
          >
            <Tilt>
              <img src={laptopImg} className="shadow rounded-3 md:mt-0 w-full mt-5" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        <MissionVision />


        <h1 className="project-heading">
          <italic className="purple" >Why Choose Us?</italic>
        </h1><br></br>



        <WhyChooseUs />

      </Container>
    </Container>
  );
}

export default About;