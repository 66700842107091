import calc from "../Assets/Projects/calc.png";
import resumeBuilder from "../Assets/Projects/resumeBuilder.png";
import flashcard from "../Assets/Projects/flashcard.png";
import crypto from "../Assets/Projects/crypto.png";
import myportfolio from "../Assets/Projects/myportfolio.png";
import getYTS from "../Assets/Projects/getYTS.png";
import myPlan from "../Assets/Projects/myPlan.png";

export const data = [
{title: ' Full-Time Employees. /Direct Hire Staffing/ Permanent Hiring Staffing.-',discreption:'Hiring permanent employees and absorbing them into your organisation is a serious commitment, so you better make sure that you’re making this commitment to the right people. We are here to help you pick out just the right ones. Permanent staffing solutions give you access to fully screened and qualified candidates in any industry of your choice .Permanent staffing is the process of providing required candidates for long-term employment based on specified candidate factors. A permanent staffing service provider accumulates the best fit candidates based on the bar of salary, experience, cultural resonance and job description to ensure complete provision of solution. A permanent staffing solution provider takes charge of finding, filtering, interviewing and placing the required talent in the organisation.',image:calc},
{title: 'Contract to Hire c2h /Temporary Staffing',discreption:'Contract to hire gives you the flexibility to augment when needed combined with the power to employ talent on a full-time basis.Zyder Solutions provides reliable temporary staffing solutions that offer you the ability to build your staff strength without absorbing them full time, assist overloaded employees during critical times, and keep projects moving , At Zyder Solutions , providing temporary staffing solutions is a hassle-free and smooth process .If you are looking for a contract staffing agency, Zyder Solutions is the perfect choice.',image:resumeBuilder},
{title: 'Remote/ Virtual IT Staffing',discreption:'Zyder Solution lends you a helping hand to offer the best remote/ virtual employees for all your staffing needs in India and also across the globe. Prior to connecting them to you, we ensure that they are timely-committed, dedicated and as professional to the work as per your needs for the same',image:flashcard},
{title: '*Project-based staffing -',discreption:'Let Zyder’s Project-Based Staffing find the right talent for all your important projects. Liberate yourself from the burden of screening, hiring, and training. We help you in finding an apt person for the job. These are a specialised Project Staffing Services requirement where the employees are hired for a specific time period for a particular project.Our experienced team in Project Staffing Services helps you achieve complete flexibility to meet the technical, budgetary and timing requirements of your strategic planning throughout the project lifecycle.',image:crypto},

]
export const zyderfunc = [
{point:'  ● Simple and effective hiring process'},
{point:'  ● You can be at peace concentrate on business activities that are critical and important for your business.'},
{point:'  ● Real-Time Tracking'},
{point:'  ● Flexible contracts'},
{point:'  ● Easy to contact us'},
{point:'  ● We can stay connected'},
{point:'  ● Careful screening of applicants'},
{point:'  ● Fast exchange of resumes of potential candidates'},
{point:'  ● Exact- match candidates'},
{point:'  ● Reliable, trust-worthy, experienced and skilled professionals'},
{point:'  ● Find the right talent at the right time'},
]                   
