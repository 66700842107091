import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Container } from "react-bootstrap";
import emailjs from '@emailjs/browser';

function FormDot() {
  const {
    control,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef();
  const onSubmit = (data) => {
    emailjs.sendForm('service_bj4o7jo', 'template_fqljbfe', form.current, 'volj7tmFhH-0YLnNN')
      .then((result) => {
        alert("Thank you for reaching out, we will get back to you");
        resetField('name');
        resetField('phone');
        resetField('message');
        resetField('email');
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))} ref={form}>
      <Container>
        <Row>
          <Col md={6} className="p-4">
            <Row className="pt-4">
              <text style={{ textAlign: "left", color: "white", fontSize: "21px", color:"#253e91" }}>Name*</text>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^[A-Za-z]+$/i,
                }}
                render={({ field }) => (
                  <input
                    className="border h-10 w-full rounded pl-3 border border-secondary"
                    {...field}
                    id="name"
                    type="text"
                    placeholder="Enter your name"
                    name='name'
                  />
                )}
              />
              {errors.name && errors.name.type === "required" && (
                <span style={{ textAlign: "left", color: "red" }}>**This field is required**</span>
              )}
              {errors.name && errors.name.type === "pattern" && (
                <span style={{ textAlign: "left", color: "red" }}>**Invalid name format**</span>
              )}
            </Row>
            <Row className="pt-5">
              <text style={{ textAlign: "left", color: "white", fontSize: "21px", color:"#253e91" }}>Email address*</text>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                }}
                render={({ field }) => (
                  <input
                    className="border h-10 w-full rounded pl-3 border border-secondary"
                    {...field}
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    name='email'
                  />
                )}
              />
              {errors.email && errors.email.type === "required" && (
                <span style={{ textAlign: "left", color: "red" }}>**This field is required**</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span style={{ textAlign: "left", color: "red" }}>**Invalid email format**</span>
              )}
            </Row>
            <Row className="pt-5">
              <text style={{ textAlign: "left", color: "white", fontSize: "21px", color:"#253e91" }}>Phone number*</text>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^[0-9\b]+$/i,
                  maxLength:15,
                  minLength:6
                }}
                render={({ field }) => (
                  <input
                    className="border h-10 w-full rounded pl-3 border border-secondary"
                    {...field}
                    id="phone"
                    type="tel"
                    placeholder="Enter your phone number"
                    name='phone'
                  />
                )}
              />
              {errors.phone && errors.phone.type === "required" && (
                <span style={{ textAlign: "left", color: "red" }}>**This field is required**</span>
              )}
              {errors.phone && errors.phone.type === "pattern" && (
                <span style={{ textAlign: "left", color: "red" }}>**Invalid phone number format**</span>
              )}
              {errors.phone && errors.phone.type === "maxLength" && (
                <span style={{ textAlign: "left", color: "red" }}>**value must be less than 15**</span>
              )}
              {errors.phone && errors.phone.type === "minLength" && (
                <span style={{ textAlign: "left", color: "red" }}>**value must be more than 6**</span>
              )}
            </Row>
          </Col>
          <Col md={6} className="p-4">
            <Row className="pt-4">
              <text style={{ textAlign: "left", color: "white", fontSize: "21px", color:"#253e91" }}>Write a message*</text>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <textarea
                    className="border w-full rounded pl-3 h-60  border border-secondary"
                    style={{ height: "279px" }}
                    {...field}
                    id="message"
                    placeholder="Enter your message"
                    name='message'
                  />
                )}
              />
              {errors.message && <span style={{ textAlign: "left", color: "red" }}>**This field is required**</span>}
            </Row>
          </Col>
          <button
            className="bg-blue-600 text-white my-4 rounded h-12"
            type="submit"
          >
            Inquire
          </button>
        </Row>
      </Container>
    </form>
  );
}
export default FormDot;
