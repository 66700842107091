import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";




function AboutCard() {
  return (
    <div>
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify",color:"black", fontFamily:"'Poppins', sans-serif" }}>
            <span className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Training </span>
            At Zyder, we believe that continuous learning and development are 
            crucial for staying ahead in the IT industry. That's why we offer 
            customised training services that are delivered by experienced trainers. 
            Whether you're looking to improve your team's software development 
            skills, project management expertise, or cybersecurity know-how, 
            we've got you covered.
            <br />< br />
            <br/>
            Our training services include:
            <br />
            <br />
            < p/>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Software development training
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Project management training
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Cybersecurity training
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            IT infrastructure training
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Data analytics training
            </p>
            </div>
            </div>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
   
</div>
  );
}

export default AboutCard;
