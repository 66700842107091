import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import myImg from "../../Assets/avatar.svg";

import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Partner from "./Partner";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
        <h1 style={{ fontSize: "2.6em", fontFamily:"'Poppins', sans-serif" }} className='text-black'>
             <span className="purple" style={{fontFamily:"'Poppins', sans-serif"}}> Achieve Optimised Efficiencies Intelligently.</span> 
            </h1>
          <Col md={8} className="home-about-description">
             <p className="home-about-body text-black" style={{textAlign:"justify", fontFamily:"'Poppins', sans-serif"}}>
            Zyder Solutions to Managed Services, today most organizations need a complete spectrum of intelligent business solutions. They need experienced partners with expertise who can provide value, flexibility and financial predictability.
              <br />
              <br />Plus talent needs opportunities to apply their intellect and progress in their career.
              <br />
              <br />
              We empower both and are the eco system that connects people, technologies and opportunities with intelligence
            </p>
          </Col>
          <Col md={4} className="home-about-description" style={{alignItems: "center", justifyContent: "center", display:"fles"}}>
            <Tilt>
              <img src={require('../../Assets/intro.jpg')} className="shadow rounded-3" style={{marginTop: "50px"}} alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <h1 className="my-5 text-[#263f92]" style={{fontFamily:"'Poppins', sans-serif"}}>What We Offer</h1>
        <Row className="mt-4">
          <Col md={6} className="mb-5 ">
            <div className="card mx-2 h-300">
              <img
                src={require('../../Assets/pic-5.jpeg')}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
              <div className="card-body">
                <h5 className="text-left text-red-800" style={{fontFamily:"'Poppins', sans-serif"}}>SOW Project Based Soultion</h5>
                <div className="bg-[#fca035] w-14 h-0.5"></div>
                <p className="card-text text-black text-left text-justify" style={{fontFamily:"'Poppins', sans-serif"}}>Zyder Solutions leverages the larger talent sourcing engine to build optimized delivery teams to achieve exceptional business results for our customers. We provide you with a combination of people, processes, tools and technologies that can help you maximize productivity, improve operations, facilitate agility and scale, reshape spending, and deliver innovation</p>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-5">
            <div className="card mx-2 h-300">
              <img
                src={require('../../Assets/pic-4.jpeg')}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> 
              <div className="card-body">
                <h5 className="text-left text-red-800" style={{fontFamily:"'Poppins', sans-serif"}}>Zyder Solutions</h5>
                <div className="bg-[#fca035] w-14 h-0.5"></div>
                <p className="card-text text-black text-left text-justify" style={{fontFamily:"'Poppins', sans-serif"}}>Our Zyder Solutions Teams don't just deliver staffing solutions - they provide business solutions. Our understanding of client's needs, innovative talent supply chain, high-performing teams, and flexible engagement models help us empower businesses by delivering human intellect that can make a difference to an organization.</p>
              </div>
            </div>
          </Col>
         
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>FIND US ON</h1>
            <p className="text-black">
              <span className="text-black" style={{fontFamily:"'Poppins', sans-serif"}}>Feel free to connect with us</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/Zyder-Solutions-119765751039863"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook className="mt-2 mx-auto" style={{color: '#253e91'}} />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Zydersolutions"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter className="mt-2 mx-auto" style={{color: '#253e91'}}/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/zyder-solutions/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn className="mt-2 mx-auto" style={{color: '#253e91'}}/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/zydersolutions/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram className="mt-2 mx-auto" style={{color: '#253e91'}}/>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;