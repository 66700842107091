import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../Assets/it-2.jpeg";
import Tilt from "react-parallax-tilt";
import { Col, Container, Row } from 'react-bootstrap';

class FadeInComoponent extends React.Component {
  render() {
    return (
      <div>
        <Fade left>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'row', fontFamily:"'Poppins', sans-serif"}}>
            <Container>
              <Row>
                <Col md={8}>
                <span>
            <text>
            ● To know more about Clients , their background , 
            current role on the project or in current company.
            <br/>
            <br/>
            ● To know more about current challenges.
            <br/>
            <br/>
            ● To understand about client's budget and timeline – To 
            have an idea of how big or small we should solve initially.
            <br/>
            <br/>
            ● To know why you contacted Zyder solutions , why do you think we 
            might be a good fit for their project? Is there something on 
            our website that caught your attention?
            <br/>
            <br/>
            ● To know what your expectations are with us.
            </text>
            </span>
                </Col>
                <Col md={4}>
                <span>
            <Tilt>
              <img src={homeLogo} className="shadow rounded-3 md:mt-0 w-full my-5" alt="avatar" />
            </Tilt>
            </span>
                </Col>
              </Row>
            </Container>
            
           

            
          </div>
        </Fade>
      </div>
    );
  }
}

export default FadeInComoponent;