import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepFive extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Testing</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            
Testing at TopG starts from the initial devel and continues till project delivery by experience Testing professionals conduct all type of desired te objective in mind i.e. Bug Free Project Delivery.

            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepFive;