import React from 'react';
import Fade from 'react-reveal/Fade';

class StepOne extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Duration</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Short-term courses: 2 months.
            Other development courses may range from 3 months up to 18 months.
            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepOne;