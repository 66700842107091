import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMobile } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { CgDesktop } from "react-icons/cg";
import { CgCloud } from "react-icons/cg";
import { FaTools } from "react-icons/fa";
import { CgDatabase } from "react-icons/cg";
import { CgIfDesign } from "react-icons/cg";
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" >
      <FaMobile className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Mobile</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>React Native, Flutter, Ionic, Xamarin, iOS (Objective-C, Swift, etc), Android (Java, Kotlin)</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons" >
      <AiFillSetting className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Backend</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>Python, Go, Golang ,Node, Ruby,Ruby On Rails , Elixir, Scala, Java, Kotlin, C/C++, Rust , Flask</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons" >
      <CgDesktop className="purple" style={{fontFamily:"'Poppins', sans-serif"}}/>
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Frontend</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>JavaScript, Typescript, React, Vue, Angular, GraphQL, Ember , NextJS , ExpressJS</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons" >
      <CgCloud className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>DevOps & Cloud</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>Kubernetes, Docker, Terraform, Ansible, Gradle, Git, Jenkins, Nagios, AWS, GCP, Azure , CircleCI</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons" >
      <FaTools className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Testing</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>Meter , PyTest , Mocha ,RSpec ,,Redis</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons"  >
      <CgDatabase className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Database</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>Neo4j , MongoDB , Cassandra , SQL Server , MySQL , PostgreSQL</p>
        
     
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <CgIfDesign className="purple" />
      
        <h3 className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Design</h3>
        <></>
        <p style={{fontSize: "12px", color:"black", fontFamily:"'Poppins', sans-serif"}}>Illustrator ,photoshop,Figma</p>
        
     
      </Col>
    </Row>
  );
}

export default Techstack;
