import React from 'react';
import Fade from 'react-reveal/Fade';

class StepOne extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Eligibility</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            All you have to have an eligibility criteria to enrol in the Arena animation for web development course is
            to have the basic knowledge about how the computer works and how we do coding and other skills
            related to development. </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepOne;