import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col className="home-header">
              <h1 style={{ paddingBottom: 15, fontFamily:"'Poppins', sans-serif" }} className="heading text-center">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name text-center" style={{fontFamily:"'Poppins', sans-serif"}}>
                Welcome to 
                <strong className="main-name"> </strong>
              </h1>

              <div className="text-center" style={{ paddingLeft: 44, textAlign: "left",marginBottom:84 }}>
                <Type />
              </div>
            </Col> 
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
