import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../Assets/it-3.jpeg";
import Tilt from 'react-parallax-tilt';
import { Container,Row,Col } from 'react-bootstrap';

class FadeInComoponentOne extends React.Component {
  render() {
    return (
      <div>
        <Fade right>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'row'}}>

            <Container>
              <Row>
                <Col md={8}><span>
                <h3>Pre-Project Consultation</h3>
                <br />  
            <text style={{paddingtop: "100px", fontFamily:"'Poppins', sans-serif"}} >
            For better results during the software development process , we begin 
            with avoiding failure in the early stage by consulting with our 
            clients for their needs. Before we start working with our Clients 
            we believe in knowing more about them.
            <br/>
            <br/>
            To determine our clients goals and objectives, we consult with 
            them to better understand what they do and what they want to 
            accomplish. Before starting a project we come up with solid 
            strategies which are built after consulting with clients and as 
            per their business needs.
            </text>
            </span></Col>
                <Col md={4}>  <span>
            <Tilt>
              <img src={homeLogo} className="shadow rounded-3 md:mt-0 w-full my-4" alt="avatar" />
            </Tilt>
            </span>
            </Col>
              </Row>
            </Container>
            
          

            
          </div>
        </Fade>
      </div>
    );
  }
}

export default FadeInComoponentOne;