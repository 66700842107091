import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepOne extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Strategy</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Based on meeting with clients , as per their budget & requirements as well as team availability,
            we build a project plan & Strategies to move ahead. We start with using clients core business 
            objectives to outline a plan that achieves clients goals and removes as much uncertainty as possible. Every 
            software development project starts with a central idea

            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepOne;