import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepSix extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Implementation</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Implementation of a project is a crucial and exciting stage of software development because the customer's idea becomes reality at this stage.
All project stakeholders are involved throughout the implementation phase to ensure 100% client satisfaction.

            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepSix;