import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../Assets/it-4.jpeg";
import Tilt from 'react-parallax-tilt';
import { Col, Container, Row } from 'react-bootstrap';

class FadeInComoponentTwo extends React.Component {
  render() {
    return (
      <div>
        <Fade left>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'row'}}>
            <Container>
              <Row>
                <Col md={8}><span>
                <h3>Project Discussion</h3>
                <br />
            <text style={{paddingtop: "100px", fontFamily:"'Poppins', sans-serif"}} >
            In order to come up with the best of the results, we consult with
            our seniour team to check if we are
            <br/>
            <br/>
            <h5>Assess Fit</h5>
            Are we fit for each other? Can we deliver service to you ?
            <br/>
            <br/>
            <h5>Refine Idea</h5>
            We want to fully understand your technology roadmap and validate your assumptions.            
            <br/>
            <br/>
            <h5>Budget</h5>
            We work with you to establish a responsible budget that incorporates a comprehensive plan. We then build a proposal within the agreed-upon budge5            
            </text>
            </span></Col>
                <Col md={4}>
                <span>
            <Tilt>
              <img src={homeLogo} className="shadow rounded-3 md:mt-0 w-full my-5" alt="avatar" />
            </Tilt>
            </span>
                </Col>
              </Row>
            </Container>
            
           

            
          </div>
        </Fade>
      </div>
    );
  }
}

export default FadeInComoponentTwo;