import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepSeven extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Maintenance</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            After successful implementation of a project, proper assistance is offered to the client for a defined period of time and as per the project requirement.
This is the last stage at which clients actually test the project thoroughly and get all issues fixed.
Moving forward, we accept feedback and keep you flexible as your business grows by:
Structuring new feature rollouts.
Handling support inquiries.
Tackling new features required by your users.

            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepSeven;