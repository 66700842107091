import React from 'react';
import Fade from 'react-reveal/Fade';

class StepOne extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Mode of training</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            Zyder Solutions provides both, online as well as offline training. </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepOne;