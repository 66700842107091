import React from 'react';
import Fade from 'react-reveal/Fade';
import homeLogo from "../../../Assets/home-main.svg";

class StepFour extends React.Component {
  render() {
    return (
      <div>
        <Fade down>
          <div className="fading-box" style={{display: 'flex', flexDirection: 'column'}}>
            <h1 style={{fontFamily:"'Poppins', sans-serif"}}>Launch</h1>
            <br />
            <p className="steps-para" style={{fontFamily:"'Poppins', sans-serif"}}>
            From server setup to data storage and deployment, we support your team through launch. And if your software application requires deployment to the app store, we handle that, too!

            </p>
            
          </div>
        </Fade>
      </div>
    );
  }
}

export default StepFour;