import React from "react";
import { Container } from "react-bootstrap";
import OurServicesLogo from "../../Assets/ser-1.webp";
import TechnologiesCard from "./Technologies";
import { ImPointRight } from "react-icons/im";


function OurServices() {
  return (
    <Container fluid className="about-section">
      <Container>
        <div className="OurServices-page">
           <div>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", fontFamily:"'Poppins', sans-serif" }}>
            <strong className="purple h-margin" style={{fontFamily:"'Poppins', sans-serif"}}> Our Services</strong>
            </h1>
          </div>
          <div className="image-OurServices">
            <img src={OurServicesLogo} width="800px" />
          </div>
          <div>
            <br />
            <br />
            <br />
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
               <strong className="purple" style={{fontFamily:"'Poppins', sans-serif"}}> We are Thrilled to provide services under these technologies:-</strong>

            </h1>
          </div>
          <TechnologiesCard />
          <div>

            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>

               <strong className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Services Included:-</strong>
              </h1>
              </div>
              <p style={{textAlign:"justify", color:"black", fontFamily:"'Poppins', sans-serif"}}>E-Commerce Solutions, 
Web App Development,
Custom Software Applications,
Website Development,
Mobile Apps Development,
Cloud Solutions / Cloud computing / Cloud Application Development Enterprise Application Services,
Full Stack Development,
Web Design,
Hire Developer,
CMS /CRM Solutions,
U.S. managed offshore developers,
Software Testing & QA Services,
IT Consulting,
Offshore Product Development</p>
<br />
<br />

<h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
               <strong className="purple" style={{fontFamily:"'Poppins', sans-serif"}}>Our solutions and services are focused on :-</strong>
              </h1>
              <br />
<div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}} >
            Medical & Healthcare Education,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Media & Entertainment Retail & Wholesale,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Consumer Products,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Real Estate,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Automotive,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Banking & Financial Services, Healthcare,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Manufacturing,
            </p>
            </div>
            </div>
            <div className="pointer-img">
              <div><ImPointRight /></div>
              <div className="pointer-points">
            <p className="purple-pointer" style={{fontFamily:"'Poppins', sans-serif"}}>
            Travel and Hospitality
            </p>
            </div>
            </div>
        </div>

      </Container>
    </Container>
  )
}
export default OurServices;